<template>
    <v-form ref="form" id="form" style="width: 100%">
        <v-row>
            <v-col cols="12">
                <v-btn class="ma-1" large solo depressed color="secondary" @click="show_add_dialog">
                    <v-icon class="mr-2">group_add</v-icon>
                    <span class="subtitle-2">Añadir registrados</span>
                </v-btn>
                <v-btn class="ma-1" large solo depressed color="secondary" @click="show_import_dialog">
                    <v-icon class="mr-2">cloud_upload</v-icon>
                    <span class="subtitle-2">Importar nuevos</span>
                </v-btn>
                <v-btn class="ma-1" large solo depressed color="green" dark @click="download_format">
                    <v-icon class="mr-2">get_app</v-icon>
                    <span class="subtitle-2">Descargar formato</span>
                </v-btn>
            </v-col>
            <v-col cols="12" md="4">
                <DashCard title="Información general" title-icon="info" body-class="pa-2">
                    <v-row class="ma-0">
                        <v-col cols="12" md="6">
                            <v-label>Nombre</v-label>
                            <span class="d-block subtitle-1 text--primary">{{ item.nombre }}</span>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-label>Disponible</v-label>
                            <span class="d-block subtitle-1 text--primary">{{ item.fecha_inicio | moment("L") }} al {{ item.fecha_termino | moment("L") }}</span>
                        </v-col>
                    </v-row>
                </DashCard>
            </v-col>
            <v-col cols="12" md="4">
                <DashCard title="Evaluador asignado" title-icon="account_circle" body-class="pa-0">
                    <v-row class="ma-0">
                        <v-col cols="12">
                            <v-card outlined>
                                <v-list no-gutters class="ma-0 pa-0">
                                    <v-list-item>
                                        <v-list-item-avatar>
                                            <v-icon x-large color="primary">account_circle</v-icon>
                                        </v-list-item-avatar>
                                        <v-list-item-content>
                                            <v-list-item-subtitle>
                                                {{ item.evaluador.rut }}
                                            </v-list-item-subtitle>
                                            <v-list-item-title>
                                                {{ item.evaluador.first_name + ' ' + item.evaluador.last_name }}
                                            </v-list-item-title>
                                            <v-list-item-subtitle>
                                                {{ item.evaluador.empresa.nombre }}
                                            </v-list-item-subtitle>
                                            <v-list-item-subtitle>
                                                {{ item.evaluador.gerencia.nombre }}
                                            </v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list>
                            </v-card>
                        </v-col>
                    </v-row>
                </DashCard>
            </v-col>
            <v-col cols="12" md="4">
                <DashCard title="Cursos" title-icon="class" body-class="pa-0">
                    <v-data-table dense :items="item.cursos" :headers="curso_headers" hide-default-header>
                        <template v-slot:item.nombre="{ item }">
                            <v-icon color="secondary" left>folder</v-icon>{{ item.nombre }}
                        </template>
                    </v-data-table>
                </DashCard>
            </v-col>
            <v-col cols="12">
                <DashCard :title="`Participantes (${item.participantes.length})`" title-icon="group" body-class="pa-0">
                    <ParticipanteMassEditor v-model="item.participantes" ref="participantes" :empresas="empresas" :gerencias="gerencias" />
                </DashCard>
            </v-col>
            <v-col cols="12">
                <DashCard title="Consideraciones">
                    <span class="subtitle-1">
                        <ul>
                            <li>Al guardar cambios, puede ocurrir que <b>la lista de participantes se verá reducida en cantidad</b>, debido a que se omitirán participantes duplicados, en caso que los haya.</li>
                        </ul>
                    </span>
                    <div class="mt-2 text-right">
                        <v-btn depressed color="primary" class="subtitle-2" large @click="upload">
                            Guardar cambios
                        </v-btn>
                        <v-btn depressed color="primary" class="subtitle-2 ml-2" large exact :to="{ name: 'dashboard_groups_list' }">
                            Descartar
                        </v-btn>
                    </div>
                </DashCard>
            </v-col>
        </v-row>
        <DownloadDialog ref="down_dialog" />
        <DialogAddParticipante ref="add_dialog" :personas="list_participantes" @success="add_to_list" />
        <DialogImportParticipantes ref="import_dialog" @success="add_to_list" />
        <Message ref="success" @onClose="$router.push({ name: 'dashboard_groups_list' })" />
        <Message ref="error" />
    </v-form>
</template>

<script>
import DialogImportParticipantes from '@/educap/dialogs/DialogImportParticipantes'
import DialogAddParticipante from '@/educap/dialogs/DialogAddParticipante'
import DatePickerText from '@/shared/components/DatePickerText'
import CursoListEditor from '@/educap/components/CursoListEditor'
import ParticipanteMassEditor from '@/educap/components/ParticipanteMassEditor'
import DashCard from '@/shared/components/DashCard'
import Message from '@/shared/components/Message'
import DownloadDialog from '@/shared/components/DownloadDialog'
import { axiosHelper, messages, valid_required } from '@/helpers'

export default {
    components: {
        CursoListEditor,
        DownloadDialog,
        DialogAddParticipante,
        DialogImportParticipantes,
        ParticipanteMassEditor,
        DatePickerText,
        DashCard,
        Message
    },
    data: () => ({
        item: {
            participantes: []
        },
        curso_headers: [
            { value: "nombre", text: "Nombre" }
        ],
        gerencias: [],
        empresas: [],
        list_participantes: [],
        rules: {
            required: valid_required
        }
    }),
    methods: {
        download_format() {
            this.$refs.down_dialog.show("edu/grupos/formato/");
        },
        show_add_dialog() {
            this.$refs.add_dialog.show();
        },
        show_import_dialog() {
            this.$refs.import_dialog.show();
        },
        add_to_list(items) {
            items.forEach((p, i) => {
                if(!!p.empresa) {
                    if(!p.empresa.id) {
                        this.empresas.push(p.empresa);
                    }
                }
                if(!!p.gerencia) {
                    if(!p.gerencia.id) {
                        this.gerencias.push(p.gerencia);
                    }
                }
            });

            this.item.participantes = this.item.participantes.concat(items);
            this.$forceUpdate();
        },
        upload() {
            let item = { ...this.item };

            if(!this.$refs.form.validate()) {
                this.error({ data: { detail: messages.texts["error_check_fields"] } });
                return;
            }

            if(this.item.participantes.length < 1) {
                this.error({ data: { detail: "Debe haber al menos un participante." } });
                return;
            }

            if(this.item.participantes.filter(x => !!x.gerencia) < 1) {
                this.error({ data: { detail: "Todos los participantes deben tener datos de Gerencia perteneciente" } });
                return;
            }

            if(this.item.participantes.filter(x => !!x.empresa) < 1) {
                this.error({ data: { detail: "Todos los participantes deben tener datos de Empresa perteneciente" } });
                return;
            }
            
            axiosHelper.put('edu/grupos/' + this.$route.params.id + '/', this.item)
                    .then(response => this.success(response))
                    .catch(response => this.error(response));
        },
        success(response) {
            const edit = this.$route.meta.edit_mode;
            this.$refs.success.show(messages.titles['success_generico'], messages.texts[edit ? 'success_upload_edit' : 'success_upload_new'], "success");
        },
        error(response) {
            var text = !!response.data ? response.data.detail : messages.texts['error_desconocido'];
            this.$refs.error.show(messages.titles['error_generico'], text, "error");
        }
    },
    mounted() {
        axiosHelper.get('edu/grupos/' + this.$route.params.id + '/editor/')
                .then(response => {
                    this.gerencias = response.data.gerencias;
                    this.empresas = response.data.empresas;
                    this.list_participantes = response.data.participantes;
                    this.item = response.data.item;
                });

        this.$parent.$parent.set_title("Editar participantes de grupo de acceso", "group_add");

    }
}
</script>